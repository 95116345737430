import { render, staticRenderFns } from "./PrintableConfigs.vue?vue&type=template&id=532e4878&scoped=true"
import script from "./PrintableConfigs.vue?vue&type=script&lang=js"
export * from "./PrintableConfigs.vue?vue&type=script&lang=js"
import style0 from "./PrintableConfigs.vue?vue&type=style&index=0&id=532e4878&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "532e4878",
  null
  
)

export default component.exports